import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PhoneComponent} from '@shared/components/ui/common/phone/phone.component';
import {
  LogoTextComponent
} from '@shared/components/layouts/main-layout/components/header/logo-text/logo-text.component';
import {
  SocialListAppearance,
  SocialListComponent
} from '@shared/components/ui/common/social/social-list/social-list.component';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-menu-footer',
  imports: [
    PhoneComponent,
    SocialListComponent,
    LogoTextComponent,
    TranslatePipe
  ],
  templateUrl: './menu-footer.component.html',
  styleUrl: './menu-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuFooterComponent {

  protected readonly SocialListAppearance = SocialListAppearance;
}
