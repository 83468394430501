import {computed, inject, Injectable, signal} from '@angular/core';
import {
  BurgerMenuService
} from '@shared/components/layouts/main-layout/components/burger-menu/services/burger-menu.service';

@Injectable({
  providedIn: 'root'
})
export class BlurService {
  private burgerMenuService = inject(BurgerMenuService);
  private isMenuOpened = this.burgerMenuService.isBurgerMenuOpened;
  private isCategoryMenuActive = computed(() => this.burgerMenuService.categoryMenuActiveId() !== null);
  private status = signal(false);

  isBlur = computed(() => this.status() || this.isMenuOpened() || this.isCategoryMenuActive());

  constructor() {
  }

  setStatus(status: boolean) {
    this.status.set(status);
  }

  toggleStatus() {
    this.status.update(prev => !prev);
  }
}
