<aside class="container wrapper">
  <div class="search-wrapper">
    <app-search-input #searchInputComponent [control]="search" (action)="goToSearchPage()"></app-search-input>
    @if (showClearSearch$ | async) {
      <app-icon-button [ariaLabel]="'search'" (click)="clear()">
        <app-icon [src]="'/images/icons/close.svg'" [size]="{size: IconSize['w-24']}"></app-icon>
      </app-icon-button>
    } @else {
      <button (click)="close()" class="btn-text">{{'common.values.cancel' | translate}}</button>
    }
  </div>

  @let res = results$ | async;
  @if (res && (res | hasResults)) {
    <app-search-results [results]="res" [query]="(search$ | async) || ''"></app-search-results>
  }

</aside>

