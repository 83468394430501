@let queryStr = query().trim().toLocaleLowerCase();
@let textStr = text().toLocaleLowerCase();

@if (textStr.indexOf(queryStr) !== -1) {
  <span>{{ text().substring(0, textStr.indexOf(queryStr)) }}</span>
  <span
      class="highlighted"
  >{{ text().substring(textStr.indexOf(queryStr), textStr.indexOf(queryStr) + queryStr.length) }}</span>
  <span>{{ text().substring(textStr.indexOf(queryStr) + queryStr.length, textStr.length) }}</span>
} @else {
  <span>{{ text() }}</span>
}
