import {ChangeDetectionStrategy, Component, inject, input} from '@angular/core';

import {NgClass} from '@angular/common';
import {
  SocialIcon,
  SocialIconComponent,
  SocialType
} from '@shared/components/ui/common/social/social-icon/social-icon.component';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
    selector: 'app-social-list',
    imports: [
        SocialIconComponent,
        NgClass
    ],
    templateUrl: './social-list.component.html',
    styleUrl: './social-list.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialListComponent {
  private translate = inject(TranslatePipe);
  appearance = input<SocialListAppearance>(SocialListAppearance.LIGHT);

  icons: SocialIcon[] = [
    {
      type: SocialType.FACEBOOK,
      link: this.translate.transform('common.socials.facebook'),
    },
    {
      type: SocialType.INSTAGRAM,
      link: this.translate.transform('common.socials.instagram'),
    },
    {
      type: SocialType.YOUTUBE,
      link: this.translate.transform('common.socials.youtube'),
    },
  ]
}

export enum SocialListAppearance {
  DARK  = 'dark',
  LIGHT = 'light',
}
