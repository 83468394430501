import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {Colors} from '@core/constants/colors.constants';
import {RoutesConstants} from '@core/constants/routes.constants';
import {CreateRoutePipe} from '@shared/pipes/create-route.pipe';

@Component({
  selector: 'app-logo-text',
  imports: [
    CreateRoutePipe
  ],
  templateUrl: './logo-text.component.html',
  styleUrl: './logo-text.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LogoTextComponent {
  color = input<Colors>(Colors.primary);

  protected readonly IconSize = IconSize;
  protected readonly RoutesConstants = RoutesConstants;
}
