import {Pipe, PipeTransform} from '@angular/core';
import {SearchResult} from '@pages/search/search.service';

@Pipe({
  name: 'hasResults'
})
export class HasResultsPipe implements PipeTransform {

  transform(value: SearchResult): unknown {
    return Object.values(value).some((val) => val.length > 0);
  }

}
