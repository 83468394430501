import {booleanAttribute, ChangeDetectionStrategy, Component, input, Input} from '@angular/core';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {MenuItem} from '@shared/models/entities/Menu.type';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {Colors} from '@core/constants/colors.constants';
import {MenuUrlPipe} from '@shared/pipes/menu-url.pipe';

export interface TreeNode {
  menu: MenuItem;
  children?: TreeNode[];
  isExpanded?: boolean;
}

@Component({
  selector: 'app-tree',
  imports: [
    NgForOf,
    NgIf,
    NgClass,
    IconComponent,
    MenuUrlPipe
  ],
  templateUrl: './tree.component.html',
  styleUrl: './tree.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeComponent {
  @Input() nodes: TreeNode[] = [];
  root = input(true);
  twoColumns = input(false, {transform: booleanAttribute});

  toggleExpand(node: TreeNode): void {
    node.isExpanded = !node.isExpanded;
  }

  protected readonly IconSize = IconSize;
  protected readonly Colors = Colors;
}
