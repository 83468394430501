import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MenuService} from '@shared/services/menu.service';
import {AsyncPipe} from '@angular/common';
import {PhoneComponent} from '@shared/components/ui/common/phone/phone.component';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {Colors} from '@core/constants/colors.constants';
import {
  LangSwitcherMobileComponent
} from '@shared/components/layouts/main-layout/components/header/lang-switcher/lang-switcher-mobile/lang-switcher-mobile.component';
import {DividerComponent} from '@shared/components/ui/common/divider/divider.component';
import {MenuChildStyle, MenuItem, MenuSitePosition, MenuStyle, MenuType} from '@shared/models/entities/Menu.type';
import {MenuUrlPipe} from '@shared/pipes/menu-url.pipe';
import {RoutesConstants} from '@core/constants/routes.constants';
import {TranslatePipe} from '@shared/pipes/translate.pipe';
import {map, Observable} from 'rxjs';
import {TreeComponent, TreeNode} from '@shared/components/tree/tree.component';
import {GoToProfileDirective} from '@pages/profile/go-to-profile.directive';

@Component({
  selector: 'app-footer-menu',
  imports: [
    AsyncPipe,
    PhoneComponent,
    IconButtonComponent,
    IconComponent,
    LangSwitcherMobileComponent,
    DividerComponent,
    MenuUrlPipe,
    TranslatePipe,
    TreeComponent,
    GoToProfileDirective,

  ],
  templateUrl: './footer-menu.component.html',
  styleUrl: './footer-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterMenuComponent {
  private menuService = inject(MenuService);
  private translate = inject(TranslatePipe);

  private shopMockMenu: MenuItem = {
    id: 0,
    childStyle: MenuChildStyle.SHOP,
    children: [],
    name: this.translate.transform('common.footer.shop'),
    position: 0,
    slug: '',
    style: MenuStyle.BIG_BOLD,
    type: MenuType.STATIC,
    url: null,
    isClickable: false,
  };
  private companyMockMenu: MenuItem = {
    id: 0,
    childStyle: MenuChildStyle.SHOP,
    children: [],
    name: this.translate.transform('common.footer.company'),
    position: 0,
    slug: '',
    style: MenuStyle.BIG_BOLD,
    type: MenuType.STATIC,
    url: null,
    isClickable: false,
  };
  footerShop$ = this.menuService.getMenu(MenuSitePosition.FOOTER_SHOP).pipe(
    map((menu) => [
      {
        ...this.shopMockMenu,
        children: menu,
      },
    ])
  );
  footerCompany$ = this.menuService.getMenu(MenuSitePosition.FOOTER_COMPANY).pipe(
    map((menu) => [
      {
        ...this.companyMockMenu,
        children: menu,
      },
    ])
  );
  treeCompanyData$: Observable<TreeNode[]> = this.footerCompany$.pipe(
    map((menus) => this.mapMenuToTreeNodes(menus))
  );
  treeShopData$: Observable<TreeNode[]> = this.footerShop$.pipe(
    map((menus) => this.mapMenuToTreeNodes(menus))
  );
  footerGeneral$ = this.menuService.getMenu(MenuSitePosition.FOOTER_GENERAL);

  /**
   * Recursively maps an array of `MenuItem` to an array of `TreeNode`.
   */
  private mapMenuToTreeNodes(menus: MenuItem[]): TreeNode[] {
    return menus.map((menu) => ({
      menu,
      isExpanded: false, // Default collapsed state
      children: menu.children ? this.mapMenuToTreeNodes(menu.children) : [], // Recursively map children
    }));
  }

  protected readonly IconSize = IconSize;
  protected readonly Colors = Colors;
  protected readonly RoutesConstants = RoutesConstants;
}
