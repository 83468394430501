import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, inject, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  BurgerMenuService
} from '@shared/components/layouts/main-layout/components/burger-menu/services/burger-menu.service';

@Component({
    selector: 'app-menu-button',
    imports: [CommonModule],
    templateUrl: './menu-button.component.html',
    styleUrls: ['./menu-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuButtonComponent {
  private burgerMenuService = inject(BurgerMenuService);
  isOpened = this.burgerMenuService.isBurgerMenuOpened;

  @HostListener('click') onItemClicked() {
    this.burgerMenuService.toggleBurgerMenuStatus();
  }
  @Output() menuButtonClick = new EventEmitter<void>();
}
