<nav class="menu">
  @if (menu$ | async; as menu) {
    <ul class="list container">
      @for (menuItem of menu; track menuItem.id) {
        <li class="item"
            [ngClass]="{active: menuItem.id | active : categoryMenuActiveId(), inactive: menuItem.id | inactive : categoryMenuActiveId()}"
            (mouseenter)="menuItem.children.length && setCategoryMenuActiveId(menuItem.id)"
            (mouseleave)="setCategoryMenuActiveId(null)">
          @if (menuItem.slug || menuItem.url) {
            <a [href]="menuItem | menuUrl">{{ menuItem.name }}</a>
          } @else {
            <span>{{ menuItem.name }}</span>
          }

          @if (menuItem.children.length) {
            <div class="arrow">
              <app-icon [src]="'/images/icons/chevron-down.svg'"
                        [color]="(menuItem.id | active : categoryMenuActiveId())
                          ? Colors.primary
                            : (menuItem.id | inactive : categoryMenuActiveId())
                              ? Colors['primary-40']
                              : Colors.primary"
                        [size]="{size: IconSize['w-16']}">
              </app-icon>
            </div>

            <nav class="submenu" [ngClass]="{show: menuItem.id | active : categoryMenuActiveId()}">
              <div class="container">
                <ul class="submenu-list">
                  @for (child of menuItem.children; track child.id) {
                    <li class="item">
                      <a [href]="child | menuUrl">{{ child.name }}</a>
                    </li>
                  }
                </ul>
              </div>
            </nav>
          }
        </li>
      }
    </ul>
  }
</nav>


