import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'active',
  standalone: true
})
export class ActivePipe implements PipeTransform {

  transform(menuItemId: number, activeItemId: number | null): boolean {
    return  menuItemId === activeItemId;
  }

}
