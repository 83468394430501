import { inject, Injectable, signal } from '@angular/core';
import { StorageService } from './storage.service';
import { PlatformDetectorService } from '../platform-detector.service';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService extends StorageService{
  pd: PlatformDetectorService = inject(PlatformDetectorService);
  override storage = signal( this.pd.isBrowser() && sessionStorage || undefined);
}
