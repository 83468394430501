import {ChangeDetectionStrategy, Component, inject, OnInit, viewChild} from '@angular/core';
import {SearchInputComponent} from '@shared/components/form/inputs/search-input/search-input.component';
import {FormBuilder} from '@angular/forms';
import {ButtonType} from '@shared/components/ui/buttons/button/button.component';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {debounceTime, map, of, switchMap} from 'rxjs';
import {AsyncPipe} from '@angular/common';
import {ModalService} from '@shared/components/modal/modal.service';
import {SearchResultsComponent} from '@pages/search/search-modal/search-results/search-results.component';
import {SearchService} from '@pages/search/search.service';
import {NavigateService} from '@shared/services/navigate.service';
import {HasResultsPipe} from '@pages/search/search-modal/has-results.pipe';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-search-modal',
  imports: [
    SearchInputComponent,
    IconButtonComponent,
    IconComponent,
    AsyncPipe,
    SearchResultsComponent,
    HasResultsPipe,
    TranslatePipe
  ],
  templateUrl: './search-modal.component.html',
  styleUrl: './search-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchModalComponent implements OnInit {
  private fb = inject(FormBuilder);
  private modalService = inject(ModalService);
  private searchService = inject(SearchService);
  private navigateService = inject(NavigateService);

  searchInputComponent = viewChild('searchInputComponent', {read: SearchInputComponent});

  search = this.fb.nonNullable.control('');
  search$ = this.search.valueChanges;

  results$ = this.search$.pipe(
    debounceTime(100),
    map(search => search.trim().toLocaleLowerCase()), // Trim whitespace from input
    switchMap(search =>
      search.length > 0
        ? this.searchService.getCombinedObservable(search)
        : of(this.searchService.EMPTY_RESULT) // Return empty results if search is empty
    ),
  );

  showClearSearch$ = this.search$.pipe(
    map(value => !!value.length)
  );

  ngOnInit() {
    this.focus();
  }

  clear() {
    this.search.setValue('');
  }

  close() {
    this.modalService.hideTooltip();
  }

  focus() {
    this.searchInputComponent()?.input()?.nativeElement.focus();
  }

  goToSearchPage(): void {
    const value = this.search.value;
    if(value) {
      this.navigateService.goToSearch(this.search.value);
    }
  }

  protected readonly ButtonType = ButtonType;
  protected readonly IconSize = IconSize;
}

