@let hasChildren = item().children.length;

<div class="group {{appearance()}}" [ngClass]="{show: showChildren()}">
  <div class="parent-wrapper" (click)="toggleShow()">
    <div class="item parent">
      @if (hasChildren && !item().isClickable) {
        <a class="parent-name hide-lg" [href]="item() | menuUrl">{{ item().name }}</a>
        <span class="parent-name show-lg">{{ item().name }}</span>
      } @else {
        <a class="parent-name" [href]="item() | menuUrl">{{ item().name }}</a>
      }
    </div>
    @if (hasChildren) {
      <div class="arrow">
        <app-icon [src]="'/images/icons/chevron-down.svg'"
                  [size]="{size: IconSize['w-22']}">
        </app-icon>
      </div>
    }
  </div>

  @if (hasChildren) {
    <div class="children" [@openClose]="isOpen() ? 'open' : 'closed'" [ngStyle]="{height: openedOnDesktop() ? 'auto': 'inherit'}">
      @for (child of item().children; track child.id) {
        <div class="item test-item">
          <a [href]="child | menuUrl">{{ child.name }}</a>
        </div>
      }
    </div>
  }
</div>


