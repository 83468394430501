@let menuBanners = menuBanners$ | async;

<aside class="wrapper" appClickOutOfBlock [flag]="isOpened()" (outOfBlockClick)="closeMenu()">
  <section class="section show-lg">
    <app-lang-switcher-mobile></app-lang-switcher-mobile>
    <app-icon-button class="close" (click)="closeMenu()" [ariaLabel]="'close'">
      <app-icon [src]="'/images/icons/x-close.svg'" [size]="{size: IconSize['w-18']}"></app-icon>
    </app-icon-button>
  </section>

  <section class="section show-lg">
    <app-icon-button class="menu-item" appGoToProfile fullWidth [ariaLabel]="'account'">
      @if (isBrowser()) {
        @if (user(); as user) {
          <app-first-letter [value]="user.firstName"></app-first-letter>
        }
      } @else {
        <app-icon [src]="'/images/icons/user.svg'" [size]="{size: IconSize['w-24']}"></app-icon>
      }

      <div class="user-info">
        @if (user(); as user) {
          <span>{{ user.lastName }} {{ user.firstName }}</span>
          @if (balance$ | async; as balance) {
            <app-card-balance (click)="$event.stopPropagation(); goToLokalCard()"
                              [balance]="balance.proposeBurnAmount"></app-card-balance>
          }

        } @else {
          <span>{{ 'common.burgerMenu.loginIntoAccount' | translate }}</span>
        }
      </div>

    </app-icon-button>
  </section>

  <section class="section show-lg">
    <app-icon-button class="menu-item" appGoToFavorites [ariaLabel]="'account'">
      <app-icon [src]="'/images/icons/heart.svg'" [size]="{size: IconSize['w-24']}"></app-icon>
      <span>{{ 'common.burgerMenu.favorite' | translate }}</span>
    </app-icon-button>
  </section>

  @if (menuBanners?.[0]; as banner) {
    <div class="container">
      <section class="collections show-lg" [ngStyle]="{'margin-top': '1.6rem'}">
        <app-collection [banner]="banner" [appearance]="CollectionAppearance.WIDE"></app-collection>
      </section>
    </div>
  }

  <div class="container">
    <nav class="menu">
      @if (menuBanners && menuBanners?.length) {
        <section class="collections hide-lg">
          <app-collections [collections]="menuBanners"></app-collections>
        </section>
        <section class="collections show-lg">
          <app-collections [collections]="menuBanners.slice(1)"></app-collections>
        </section>

        <div class="divider"></div>
      }

      <ul class="list">
        @for (item of menu$ | async; track item.id) {
          <li class="item" [ngClass]="{bordered: item.style === MenuStyle.BIG_BOLD}">
            <app-menu-group [appearance]="getMenuGroupAppearanceByStyle(item.style) || MenuGroupAppearance.PRIMARY"
                            [item]="item"></app-menu-group>
          </li>
        }
      </ul>
    </nav>
  </div>
  <section class="footer">
    <app-menu-footer></app-menu-footer>
  </section>
</aside>
