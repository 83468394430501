import {ChangeDetectionStrategy, Component, ElementRef, inject, viewChild} from '@angular/core';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {RoutesConstants} from '@core/constants/routes.constants';
import {BasketService} from '@shared/services/api/basket.service';
import {NgClass} from '@angular/common';
import {NavigateService} from '@shared/services/navigate.service';
import {AuthService} from '@shared/services/auth/auth.service';
import {ModalService, ModalType} from '@shared/components/modal/modal.service';
import {
  BasketTooltipComponent
} from '@shared/components/layouts/main-layout/components/header/cart-button/basket-tooltip/basket-tooltip.component';

@Component({
  selector: 'app-basket-button',
  imports: [
    IconButtonComponent,
    IconComponent,
    NgClass
  ],
  templateUrl: './basket-button.component.html',
  styleUrl: './basket-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasketButtonComponent {
  private basketService = inject(BasketService);
  private navigateService = inject(NavigateService);
  private authService = inject(AuthService);
  private modalService = inject(ModalService);
  private basketButton = viewChild('basketButton', {read: ElementRef});

  quantity = this.basketService.quantity;

  goToBasket() {
    const basketIsEmpty = this.quantity() === 0;
    const isLoggedInOrAnonymous = this.authService.isLoggedInOrAnonymous();

    if (isLoggedInOrAnonymous && !basketIsEmpty) {
      this.navigateService.goToBasket()
    } else {
      this.modalService.show({
        component: BasketTooltipComponent,
        inputs: {}
      }, ModalType.TOOLTIP, this.basketButton()?.nativeElement)
    }
  }

  protected readonly IconSize = IconSize;
  protected readonly RoutesConstants = RoutesConstants;
}
