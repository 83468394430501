<footer class="footer container">
  <section class="section">
    <app-logo-text [color]="Colors.white"></app-logo-text>
    <app-social-list [appearance]="SocialListAppearance.LIGHT"></app-social-list>
  </section>
  <app-divider [color]="Colors['white-20']"></app-divider>
  <section class="section">
    <app-footer-menu></app-footer-menu>
  </section>
  <app-divider [color]="Colors['white-20']"></app-divider>
  <div class="copyright">
    <span>{{'common.footer.copyrights' | translate}}{{', '}}{{startYear}}{{'-'}}{{year}}</span>
    <app-lang-switcher-mobile class="footer-context show-lg"></app-lang-switcher-mobile>
  </div>
</footer>
