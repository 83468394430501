import {ChangeDetectionStrategy, Component} from '@angular/core';
import {
  FooterMenuComponent
} from '@shared/components/layouts/main-layout/components/footer/footer-menu/footer-menu.component';
import {
  LogoTextComponent
} from '@shared/components/layouts/main-layout/components/header/logo-text/logo-text.component';
import {Colors} from '@core/constants/colors.constants';
import {DividerComponent} from '@shared/components/ui/common/divider/divider.component';
import {
  LangSwitcherMobileComponent
} from '@shared/components/layouts/main-layout/components/header/lang-switcher/lang-switcher-mobile/lang-switcher-mobile.component';
import {
  SocialListAppearance,
  SocialListComponent
} from '@shared/components/ui/common/social/social-list/social-list.component';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-footer',
  imports: [
    FooterMenuComponent,
    LogoTextComponent,
    SocialListComponent,
    DividerComponent,
    LangSwitcherMobileComponent,
    TranslatePipe,
  ],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent {
  year = new Date().getFullYear();
  startYear = 2010;

  protected readonly Colors = Colors;
  protected readonly SocialListAppearance = SocialListAppearance;
}
