import {
  computed,
  DestroyRef,
  Directive,
  EventEmitter,
  Inject,
  inject,
  input,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { filter, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DOCUMENT } from '@angular/common';
import { PlatformDetectorService } from '@shared/services/platform-detector.service';
import {BreakpointsConstants} from '@core/constants/breakpoints.constants';

@Directive({
  selector: '[appDisableScroll]',
  standalone: true
})
export class DisableScrollDirective implements OnInit {
  private document = inject(DOCUMENT);
  private destroyRef = inject(DestroyRef);
  private window = this.document.defaultView as Window & typeof globalThis;
  private htmlElement = this.document.documentElement;
  private bodyElement = this.document.body;
  private scrolledDistance = 0;
  screen = input<keyof typeof BreakpointsConstants>();
  private disableClass = computed(() => 'disable-scroll' + (this.screen() ? '-' + this.screen() : ''));
  @Input() status$: Observable<boolean> | undefined;
  @Output() status = new EventEmitter<boolean>();

  constructor(private pd: PlatformDetectorService) {
  }

  ngOnInit(): void {
    this.status$ && this.status$
      .pipe(takeUntilDestroyed(this.destroyRef), filter(() => this.pd.isBrowser()))
      .subscribe(status => {
        this.status.emit(status);
        if (status) {
          this.scrolledDistance = Math.abs(this.document.body.getBoundingClientRect().top);
          this.setDisableStyles();
        } else if (this.htmlElement.classList.contains(this.disableClass())) {
          this.removeDisableStyles();
          this.window.scrollTo({ top: this.scrolledDistance });
        }
      });
  }

  private setDisableStyles() {
    this.htmlElement.style.top = this.scrolledDistance * -1 + 'px';
    this.htmlElement.classList.add(this.disableClass());
    this.bodyElement.classList.add(this.disableClass());
  }

  private removeDisableStyles() {
    this.htmlElement.classList.remove(this.disableClass());
    this.bodyElement.classList.remove(this.disableClass());
    this.htmlElement.style.removeProperty('top');
  }
}
