import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';

@Component({
  selector: 'app-social-icon',
  imports: [
    IconComponent
  ],
  templateUrl: './social-icon.component.html',
  styleUrl: './social-icon.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialIconComponent {
  social = input.required<SocialIcon>();
  protected readonly IconSize = IconSize;
}

export type SocialIcon = {
  type: SocialType;
  link: string;
}

export enum SocialType {
  FACEBOOK = 'facebook',
  INSTAGRAM = 'instagram',
  YOUTUBE = 'youtube',
}
