<app-header-message #headerMessageComponent></app-header-message>
<app-header [isHeaderMessageActive]="headerMessageComponent.showMessage()"></app-header>
<app-categories-menu class="hide-lg"></app-categories-menu>

<app-blur-content [blur]="isBlur()">
  <main class="main">
    <router-outlet></router-outlet>
  </main>

  <app-footer></app-footer>

</app-blur-content>



