import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {RoutesConstants} from '@core/constants/routes.constants';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-basket-tooltip',
  imports: [
    IconComponent,
    TranslatePipe
  ],
  templateUrl: './basket-tooltip.component.html',
  styleUrl: './basket-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BasketTooltipComponent {
  protected readonly IconSize = IconSize;
  protected readonly RoutesConstants = RoutesConstants;
}
