import {ChangeDetectionStrategy, Component, HostBinding, input, Input} from '@angular/core';
import {ImageComponent} from '@shared/components/common/image/image.component';
import {MainPageBanner} from '@shared/models/entities/MainPageBanner.type';
import {ImageResizerPipe} from '@shared/pipes/image-resizer.pipe';

@Component({
    selector: 'app-collection',
    imports: [
        ImageComponent,
        ImageResizerPipe
    ],
    templateUrl: './collection.component.html',
    styleUrl: './collection.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionComponent {
  @HostBinding('class')
  @Input() appearance: CollectionAppearance = CollectionAppearance.NORMAL;

  banner = input.required<MainPageBanner>();
  protected readonly CollectionAppearance = CollectionAppearance;
}

export enum CollectionAppearance {
  WIDE = 'wide',
  NORMAL = 'normal',
}
