<nav class="row menu">
  <div class="col col-lg-5">
    @if (treeShopData$ | async; as treeData) {
      <app-tree [nodes]="treeData" twoColumns></app-tree>
    }
  </div>

  <div class="col col-lg-3">
    @if (treeCompanyData$ | async; as treeData) {
      <app-tree [nodes]="treeData"></app-tree>
    }
  </div>

  <div class="col col-lg-2">
    <ul class="list">
      @for (item of footerGeneral$ | async; track item.id) {
        <li class="item">
          <a [href]="item | menuUrl">{{ item.name }}</a>
        </li>
      }
    </ul>
  </div>

  <div class="col show-lg">
    <app-divider class="" [color]="Colors['white-20']"></app-divider>
  </div>

  <div class="col col-lg-2 info">
    <app-phone [phone]="'common.values.contactPhone' | translate"></app-phone>
    <app-icon-button appGoToProfile class="account" [ariaLabel]="'account'">
      <app-icon [src]="'/images/icons/user.svg'" [size]="{size: IconSize['w-20']}" [color]="Colors.white"></app-icon>
      <span [style.color]="Colors.white">{{ 'common.footer.account' | translate }}</span>
    </app-icon-button>
    <app-lang-switcher-mobile class="footer-context hide-lg"></app-lang-switcher-mobile>
  </div>
</nav>
