import {ChangeDetectionStrategy, Component} from '@angular/core';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {SearchModalComponent} from '@pages/search/search-modal/search-modal.component';
import {ModalType} from '@shared/components/modal/modal.service';
import {ModalDirective} from '@shared/components/modal/modal.directive';

@Component({
  selector: 'app-search',
  imports: [
    IconButtonComponent,
    IconComponent,
    ModalDirective
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent {

  protected readonly IconSize = IconSize;
  protected readonly SearchModalComponent = SearchModalComponent;
  protected readonly ModalType = ModalType;
}
