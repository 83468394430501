import {Injectable} from '@angular/core';
import {Menu, MenuSitePosition} from '@shared/models/entities/Menu.type';
import {BaseService} from '@core/services/base.service';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MenuService extends BaseService {
  constructor() {
    super('menu');
  }

  getMenu(menuPosition: MenuSitePosition): Observable<Menu> {
    const params = new HttpParams({fromObject: {sitePosition: menuPosition}});
    return this.http.get<Menu>(`${this.REST_PATH_V2}`, {params});
  }
}

