import { Injectable } from '@angular/core';
import {BaseService} from '@core/services/base.service';
import {HeaderMessage} from '@shared/models/entities/HeaderMessage.type';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderMessageService extends BaseService {

  constructor() {
    super('header-message');
  }

  getHeaderMessage(): Observable<HeaderMessage> {
    return this.http.get<HeaderMessage>(`${this.REST_PATH_V2}`)
  }
}
