<div class="lang">
  <app-icon-button [ariaLabel]="'lang-switcher'">
    <app-icon [src]="'/images/icons/globe.svg'" [size]="{size: IconSize['w-20']}"></app-icon>
  </app-icon-button>

  <div class="list">
    <ng-container
        *ngTemplateOutlet="link; context: {active: locale === Language.UK, link: Language.UK | langSwitcher, name: 'УКР'};"></ng-container>
    <span class="divider"></span>
    <ng-container
        *ngTemplateOutlet="link; context: {active:  locale === Language.EN, link: Language.EN | langSwitcher, name: 'EN'}"></ng-container>
  </div>
</div>

<ng-template #link let-active="active" let-link="link" let-name="name">
  @if (active) {
    <span class="item active">
      {{ name }}
    </span>
  } @else {
    <a class="item" [href]="link">
      {{ name }}
    </a>
  }
</ng-template>
