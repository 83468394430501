import {inject, Pipe, PipeTransform} from '@angular/core';
import {MenuItem, MenuType} from '@shared/models/entities/Menu.type';
import {RoutesConstants} from '@core/constants/routes.constants';
import {CreateCategoryRoutePipe} from '@pages/categories/create-category-route.pipe';
import {LOCALE} from '@core/tokens/Locale.token';
import {Language} from '@shared/models/common/Language.enum';

@Pipe({
  name: 'menuUrl',
  standalone: true
})
export class MenuUrlPipe implements PipeTransform {
  private createCategoryRoutePipe = inject(CreateCategoryRoutePipe);
  private locale = inject(LOCALE);

  transform(item: MenuItem): string | undefined {
    return this.getUrl(item);
  }

  getUrl(item: MenuItem): string | undefined {
    let menuUrl: string | undefined;
    const {slug, url} = item;
    const menuSlug = slug || url || '';

    if (item.category) {
      menuUrl = this.createCategoryRoutePipe.transform({...item.category});
    } else {
      const pathFromType = this.getPathFromMenuType(item);
      menuUrl = `${this.locale === Language.UK ? '' : '/' + Language.EN }/${pathFromType ? pathFromType  + '/' : ''}${menuSlug}`;
    }

    return menuUrl;

  }

  getPathFromMenuType(item: MenuItem): string {
    switch (item.type) {
      case MenuType.CATEGORY:
      case MenuType.CATEGORY_GROUP:
        return RoutesConstants.CATEGORY.path;
      case MenuType.COLLECTION:
        return RoutesConstants.COLLECTIONS.path;
      case MenuType.PROMO_GOODS:
        return RoutesConstants.PRODUCT.path;
      default:
        return '';
    }
  }
}
