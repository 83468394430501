import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';

@Component({
    selector: 'app-blur-content',
    imports: [],
    templateUrl: './blur-content.component.html',
    styleUrl: './blur-content.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BlurContentComponent {
  @HostBinding('class.blur')
  @Input() blur = false;
}
