import {Directive, HostListener, inject} from '@angular/core';
import {NavigateService} from '@shared/services/navigate.service';

@Directive({
  selector: '[appGoToProfile]'
})
export class GoToProfileDirective {
  private navigateService = inject(NavigateService);

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.navigateService.goToProfile();
  }

}
