<ul>
  @if (results().category; as categories) {
    @for (item of categories; track item.slug) {
      <li>
        <a [href]="item | createCategoryRoute">
          <app-highlight-result [query]="query()" [text]="item.name"></app-highlight-result>
          <span class="type">{{ ' / ' }}{{'category.category' | translate}}</span>
        </a>
      </li>
    }
  }

  @if (results().collection; as collections) {
    @for (item of collections; track item.slug) {
      <li>
        <a [href]="[RoutesConstants.COLLECTIONS.path, item.slug] | createRoute">
          <app-highlight-result [query]="query()" [text]="item.name"></app-highlight-result>
          <span class="type">{{ ' / ' }}{{'collection.collection' | translate}}</span>
        </a>
      </li>
    }
  }

  @if (results().product; as goods) {
    @for (item of goods.slice(0, 10); track item.slug) {
      <li>
        <a [href]="[RoutesConstants.PRODUCT.path, item.slug] | createRoute">
          <app-highlight-result [query]="query()" [text]="item.name"></app-highlight-result>
        </a>
      </li>
    }
  }
</ul>
