<header class="header" appDisableScroll [status$]="isMenuOpened$" [screen]="'lg'">
  <div #headerWrapper class="wrapper"
       [class.with-message]="isHeaderMessageActive()"
       [class.fixed]="stickHeader()"
       [class.with-divider]="stickHeader() && withDivider()"
  >
    <div class="container">
      <div class="left buttons-group">
        <app-menu-button></app-menu-button>
        <app-search></app-search>
      </div>
      <div class="center">
        <app-logo-text></app-logo-text>
      </div>
      <div class="right buttons-group">
        <app-lang-switcher [class]="'hide-md'"></app-lang-switcher>

        <app-icon-button class="hide-md" appGoToProfile [ariaLabel]="'profile'">
          @defer (on idle) {
            @if (isBrowser()) {
              @if (user(); as user) {
                <app-first-letter [value]="user.firstName"></app-first-letter>
              } @else {
                <app-icon [src]="'/images/icons/user.svg'" [size]="{size: IconSize['w-24']}"></app-icon>
              }
            }
          } @placeholder {
            <app-first-letter [value]="''"></app-first-letter>
          }

        </app-icon-button>


        <app-icon-button class="hide-md" appGoToFavorites [ariaLabel]="'favorite'">
          <app-icon [src]="'/images/icons/heart.svg'" [size]="{size: IconSize['w-24']}"></app-icon>
        </app-icon-button>

        <app-basket-button></app-basket-button>
      </div>
    </div>

    <app-burger-menu class="burger-menu" [ngClass]="{active: isMenuOpened()}"></app-burger-menu>
  </div>
</header>
