import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {NgTemplateOutlet} from '@angular/common';
import {LOCALE} from '@core/tokens/Locale.token';
import {Language} from '@shared/models/common/Language.enum';
import {
  LangSwitcherPipe
} from '@shared/components/layouts/main-layout/components/header/lang-switcher/lang-switcher.pipe';

@Component({
  selector: 'app-lang-switcher',
  imports: [
    IconButtonComponent,
    IconComponent,
    NgTemplateOutlet,
    LangSwitcherPipe
  ],
  templateUrl: './lang-switcher.component.html',
  styleUrl: './lang-switcher.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LangSwitcherComponent {
  locale = inject(LOCALE);
  protected readonly IconSize = IconSize;
  protected readonly Language = Language;
}
