import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';

@Component({
    selector: 'app-phone',
    imports: [
        IconComponent
    ],
    templateUrl: './phone.component.html',
    styleUrl: './phone.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneComponent {
  phone = input.required<string>();

  protected readonly IconSize = IconSize;
}
