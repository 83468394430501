import {Category} from '@shared/models/entities/Category.type';
import {Collection} from '@shared/models/entities/Collection.type';

export type Menu = MenuItem[]

export type MenuItem = {
  id: number,
  type: MenuType,
  url: string | null,
  style: MenuStyle,
  childStyle: MenuChildStyle,
  position: number,
  name: string,
  children: MenuItem[],
  slug: string,
  category?: Category | null,
  collection?: Collection | null,
  isClickable: boolean,
}

export enum MenuType {
  CATEGORY = 'CATEGORY',
  CATEGORY_GROUP = 'CATEGORY_GROUP',
  COLLECTION = 'COLLECTION',
  STATIC = 'STATIC',
  NEWS = 'NEWS',
  PROMO_GOODS = 'PROMO_GOODS',
  URL = 'URL',
}

export enum MenuStyle {
  BIG_BOLD = 'BIG_BOLD',
  BOLD = 'BOLD',
  REGULAR = 'REGULAR',
}

export enum MenuChildStyle {
  SHOP = 'SHOP',
  IMAGE = 'IMAGE', // імідж, не картинка
}

export enum MenuSitePosition {
  HEADER = 'HEADER',
  BURGER_MENU = 'BURGER_MENU',
  FOOTER = 'FOOTER',
  FOOTER_COMPANY = 'FOOTER_COMPANY',
  FOOTER_SHOP = 'FOOTER_SHOP',
  FOOTER_GENERAL = 'FOOTER_GENERAL',
}
