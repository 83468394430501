import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  input,
  OnInit,
  signal,
  viewChild
} from '@angular/core';
import {
  MenuButtonComponent
} from '@shared/components/layouts/main-layout/components/header/menu-button/menu-button.component';
import {SearchComponent} from '@shared/components/layouts/main-layout/components/header/search/search.component';
import {
  LogoTextComponent
} from '@shared/components/layouts/main-layout/components/header/logo-text/logo-text.component';
import {
  LangSwitcherComponent
} from '@shared/components/layouts/main-layout/components/header/lang-switcher/lang-switcher.component';
import {RoutesConstants} from '@core/constants/routes.constants';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {
  BasketButtonComponent
} from '@shared/components/layouts/main-layout/components/header/cart-button/basket-button.component';
import {BurgerMenuComponent} from '@shared/components/layouts/main-layout/components/burger-menu/burger-menu.component';
import {
  BurgerMenuService
} from '@shared/components/layouts/main-layout/components/burger-menu/services/burger-menu.service';
import {DOCUMENT, NgClass} from '@angular/common';
import {DisableScrollDirective} from '@shared/directives/disable-scroll.directive';
import {takeUntilDestroyed, toObservable} from '@angular/core/rxjs-interop';
import {ModalType} from '@shared/components/modal/modal.service';
import {GoToProfileDirective} from '@pages/profile/go-to-profile.directive';
import {combineLatest, filter, fromEvent, map, of, startWith, switchMap} from 'rxjs';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';
import {Values} from '@core/constants/values.constants';
import {GoToFavoritesDirective} from '@pages/profile/favorites/go-to-favorites.directive';
import {AuthService} from '@shared/services/auth/auth.service';
import {FirstLetterComponent} from '@shared/components/ui/common/first-letter/first-letter.component';

@Component({
  selector: 'app-header',
  imports: [
    MenuButtonComponent,
    SearchComponent,
    LogoTextComponent,
    LangSwitcherComponent,
    IconButtonComponent,
    IconComponent,
    BasketButtonComponent,
    BurgerMenuComponent,
    NgClass,
    DisableScrollDirective,
    GoToProfileDirective,
    GoToFavoritesDirective,
    FirstLetterComponent
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  private authService = inject(AuthService);
  private burgerMenuService = inject(BurgerMenuService);
  private document = inject(DOCUMENT);
  private pd = inject(PlatformDetectorService);
  private destroyRef = inject(DestroyRef);
  isMobile$ = toObservable(this.pd.isMobileScreen);

  isHeaderMessageActive = input(false);
  isHeaderMessageActive$ = toObservable(this.isHeaderMessageActive);
  stickHeader = signal(false);
  withDivider = signal(false);
  isBrowser = this.pd.isBrowser;
  isServer = this.pd.isServer;
  user = this.authService.loadedUser;

  headerWrapper = viewChild('headerWrapper', {read: ElementRef<HTMLDivElement>});

  isMenuOpened = this.burgerMenuService.isBurgerMenuOpened;
  isMenuOpened$ = toObservable(this.isMenuOpened);

  ngOnInit() {
    this.handleHeaderSticky();
  }

  handleHeaderSticky() {
    this.isMobile$.pipe(
      filter(() => this.pd.isBrowser()),
      switchMap(isMobile =>
        combineLatest([
          fromEvent(this.document, 'scroll').pipe(startWith(0)), // Start with null to emit initial state
          of(isMobile),
          this.isHeaderMessageActive$,
        ]).pipe(
          map(([_, isMobile, isHeaderMenuActive]) => [isMobile, isHeaderMenuActive]),
          takeUntilDestroyed(this.destroyRef),
        )
      )
    ).subscribe(([isMobile, isHeaderMenuActive]) => {
      const headerWrapperEl = this.headerWrapper()?.nativeElement;
      if (!headerWrapperEl || !this.document.defaultView) {
        return;
      }
      const showHeaderMessageHeight = isMobile ? Values.HEADER_MESSAGE_HEIGHT_MOBILE : Values.HEADER_MESSAGE_HEIGHT;
      const scrollY = this.document.defaultView.scrollY;
      const stickHeader = isHeaderMenuActive
        ? scrollY >= showHeaderMessageHeight
        : scrollY >= 0;
      this.stickHeader.set(stickHeader);
      this.withDivider.set(scrollY > 0);
    });
  }

  protected readonly IconSize = IconSize;
  protected readonly ModalType = ModalType;
  protected readonly RoutesConstants = RoutesConstants;
}
