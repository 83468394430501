import {inject, Pipe, PipeTransform} from '@angular/core';
import {Language} from '@shared/models/common/Language.enum';
import {Location} from '@angular/common';

@Pipe({
  name: 'langSwitcher'
})
export class LangSwitcherPipe implements PipeTransform {
  private location = inject(Location);

  transform(locale: Language): string {
    const currentPath = this.location.path();

    // Build a regex pattern for all languages in the enum
    const languagesPattern = Object.values(Language).join('|');
    const pathWithoutLang = currentPath.replace(new RegExp(`^/(${languagesPattern})(/|$)`), '/');

    return `${locale === Language.UK ? '' : '/' + locale}${pathWithoutLang}`;
  }

}
