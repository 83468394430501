import {Component, inject} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {HeaderComponent} from '@shared/components/layouts/main-layout/components/header/header.component';
import {
  HeaderMessageComponent
} from '@shared/components/layouts/main-layout/components/header-message/header-message.component';
import {
  CategoriesMenuComponent
} from '@shared/components/layouts/main-layout/components/categories-menu/categories-menu.component';
import {BlurService} from '@shared/services/blur.service';
import {
  BlurContentComponent
} from '@shared/components/layouts/main-layout/components/blur-content/blur-content.component';
import {FooterComponent} from '@shared/components/layouts/main-layout/components/footer/footer.component';
import {IconSize} from '@shared/components/ui/icons/icon/icon.component';

@Component({
  selector: 'app-main-layout',
  imports: [
    RouterOutlet,
    HeaderComponent,
    HeaderMessageComponent,
    CategoriesMenuComponent,
    BlurContentComponent,
    FooterComponent
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent {
  private blurService = inject(BlurService);

  isBlur = this.blurService.isBlur;
  protected readonly IconSize = IconSize;
}
