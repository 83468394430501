<ul class="list lvl-1">
  <ng-container *ngFor="let node of nodes">
    @let hasChildren = node.children?.length;
    <li class="item">
      <div class="node" [ngClass]="{'underlined root': root(), 'two-columns': twoColumns()}"
           (click)="toggleExpand(node)">
        @let url = node.menu | menuUrl;
        @if (url && !root()) {
          @if (hasChildren && !node.menu.isClickable) {
            <a class="hide-lg" [href]="url">{{ node.menu.name }}</a>
            <span class="show-lg">{{ node.menu.name }}</span>
          } @else {
            <a class="" [href]="url">{{ node.menu.name }}</a>
          }
        } @else {
          <span>{{ node.menu.name }}</span>
        }

        @if (hasChildren) {
          <div class="arrow show-lg" [ngClass]="{'active': node.isExpanded}">
            <app-icon [src]="'/images/icons/chevron-down.svg'" [color]="Colors.white"
                      [size]="{size: IconSize['w-22']}">
            </app-icon>
          </div>
        }
      </div>

      <!-- Recursive Children -->
      <ul *ngIf="node.children && hasChildren"
          [class.open]="node.isExpanded"
          [class]="'list lvl-2'">
        <app-tree [nodes]="node.children" [root]="false"></app-tree>
      </ul>
    </li>
  </ng-container>
</ul>
