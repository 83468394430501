import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {
  CollectionsComponent
} from '@shared/components/layouts/main-layout/components/burger-menu/collections/collections.component';
import {ClickOutOfBlockDirective} from '@shared/directives/click-out-of-block.directive';
import {
  BurgerMenuService
} from '@shared/components/layouts/main-layout/components/burger-menu/services/burger-menu.service';
import {MenuService} from '@shared/services/menu.service';
import {AsyncPipe, NgClass, NgStyle} from '@angular/common';
import {
  MenuGroupAppearance,
  MenuGroupComponent
} from '@shared/components/layouts/main-layout/components/burger-menu/menu-group/menu-group.component';
import {
  MenuFooterComponent
} from '@shared/components/layouts/main-layout/components/burger-menu/menu-footer/menu-footer.component';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {
  LangSwitcherMobileComponent
} from '@shared/components/layouts/main-layout/components/header/lang-switcher/lang-switcher-mobile/lang-switcher-mobile.component';
import {MenuSitePosition, MenuStyle} from '@shared/models/entities/Menu.type';
import {RoutesConstants} from '@core/constants/routes.constants';
import {BannersService} from '@shared/services/api/banners.service';
import {BannerType} from '@shared/models/entities/MainPageBanner.type';
import {
  CollectionAppearance,
  CollectionComponent
} from '@shared/components/layouts/main-layout/components/burger-menu/collections/collection/collection.component';
import {TranslatePipe} from '@shared/pipes/translate.pipe';
import {GoToProfileDirective} from '@pages/profile/go-to-profile.directive';
import {GoToFavoritesDirective} from '@pages/profile/favorites/go-to-favorites.directive';
import {AuthService} from '@shared/services/auth/auth.service';
import {CardBalanceComponent} from '@pages/profile/lokal-card/card-balance/card-balance.component';
import {LokalService} from '@shared/services/auth/lokal.service';
import {toObservable} from '@angular/core/rxjs-interop';
import {filter, switchMap, take} from 'rxjs';
import {NavigateService} from '@shared/services/navigate.service';
import {FirstLetterComponent} from '@shared/components/ui/common/first-letter/first-letter.component';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';

@Component({
  selector: 'app-burger-menu',
  imports: [
    CollectionsComponent,
    ClickOutOfBlockDirective,
    AsyncPipe,
    NgClass,
    MenuGroupComponent,
    MenuFooterComponent,
    IconButtonComponent,
    IconComponent,
    LangSwitcherMobileComponent,
    CollectionComponent,
    NgStyle,
    TranslatePipe,
    GoToProfileDirective,
    GoToFavoritesDirective,
    CardBalanceComponent,
    FirstLetterComponent,
  ],
  templateUrl: './burger-menu.component.html',
  styleUrl: './burger-menu.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BurgerMenuComponent {
  private menuService = inject(MenuService);
  private burgerMenuService = inject(BurgerMenuService);
  private bannersService = inject(BannersService);
  private MAX_BANNERS_COUNT = 4;
  private lokalService = inject(LokalService);
  private navigateService = inject(NavigateService);
  private platformDetectorService = inject(PlatformDetectorService);

  isBrowser = this.platformDetectorService.isBrowser;
  isOpened = this.burgerMenuService.isBurgerMenuOpened;
  user = inject(AuthService).loadedUser;
  balance$ = toObservable(this.isOpened).pipe(
    filter(opened => opened),
    take(1),
    switchMap(() => this.lokalService.checkCard()),
  )

  menu$ = this.menuService.getMenu(MenuSitePosition.BURGER_MENU);
  menuBanners$ = this.bannersService.getByType({type: BannerType.MENU_BANNER, limit: this.MAX_BANNERS_COUNT});

  getMenuGroupAppearanceByStyle(style: MenuStyle) {
    const styleMap = new Map([
      [MenuStyle.REGULAR, MenuGroupAppearance.SECONDARY],
      [MenuStyle.BOLD, MenuGroupAppearance.PRIMARY],
      [MenuStyle.BIG_BOLD, MenuGroupAppearance.TERTIARY],
    ]);

    return styleMap.get(style);
  }

  closeMenu() {
    this.burgerMenuService.setBurgerMenuStatus(false);
  }

  goToLokalCard() {
    this.navigateService.goToLokal();
  }

  protected readonly IconSize = IconSize;
  protected readonly MenuGroupAppearance = MenuGroupAppearance;
  protected readonly MenuStyle = MenuStyle;
  protected readonly RoutesConstants = RoutesConstants;
  protected readonly CollectionAppearance = CollectionAppearance;
}
