import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {HighlightResultComponent} from '@pages/search/search-modal/highlight-result/highlight-result.component';
import {RoutesConstants} from '@core/constants/routes.constants';
import {CreateRoutePipe} from '@shared/pipes/create-route.pipe';
import {SearchResult} from '@pages/search/search.service';
import {CreateCategoryRoutePipe} from '@pages/categories/create-category-route.pipe';
import {TranslatePipe} from '@shared/pipes/translate.pipe';

@Component({
  selector: 'app-search-results',
  imports: [
    HighlightResultComponent,
    CreateRoutePipe,
    CreateCategoryRoutePipe,
    TranslatePipe
  ],
  templateUrl: './search-results.component.html',
  styleUrl: './search-results.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchResultsComponent {
  query = input.required<string>();
  results = input.required<SearchResult>();
  protected readonly RoutesConstants = RoutesConstants;
  protected readonly Object = Object;
}
