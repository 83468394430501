import {ChangeDetectionStrategy, Component, input} from '@angular/core';

@Component({
  selector: 'app-highlight-result',
  imports: [],
  templateUrl: './highlight-result.component.html',
  styleUrl: './highlight-result.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HighlightResultComponent {
  query = input.required<string>();
  text = input.required<string>();
}
