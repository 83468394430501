import {ChangeDetectionStrategy, Component, inject} from '@angular/core';
import {MenuService} from '@shared/services/menu.service';
import {AsyncPipe, NgClass} from '@angular/common';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {Colors} from '@core/constants/colors.constants';
import {ActivePipe} from '@shared/components/layouts/main-layout/components/categories-menu/active.pipe';
import {InactivePipe} from '@shared/components/layouts/main-layout/components/categories-menu/inactive.pipe';
import {
  BurgerMenuService
} from '@shared/components/layouts/main-layout/components/burger-menu/services/burger-menu.service';
import {MenuSitePosition} from '@shared/models/entities/Menu.type';
import {MenuUrlPipe} from '@shared/pipes/menu-url.pipe';

@Component({
    selector: 'app-categories-menu',
    imports: [
        AsyncPipe,
        IconComponent,
        NgClass,
        ActivePipe,
        InactivePipe,
        MenuUrlPipe
    ],
    templateUrl: './categories-menu.component.html',
    styleUrl: './categories-menu.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CategoriesMenuComponent {
  private menuService = inject(MenuService);
  private burgerMenuService = inject(BurgerMenuService);
  menu$ = this.menuService.getMenu(MenuSitePosition.HEADER);
  categoryMenuActiveId = this.burgerMenuService.categoryMenuActiveId;
  private timeout: NodeJS.Timeout | undefined;

  constructor() {
  }

  setCategoryMenuActiveId(categoryMenuActiveId: number | null) {
    if (this.timeout) {
      console.log(this.timeout)
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.burgerMenuService.setCategoryMenuActiveId(categoryMenuActiveId);
    }, categoryMenuActiveId ? 0 : 200)
  }

  protected readonly IconSize = IconSize;
  protected readonly Colors = Colors;
}
