import {Injectable, signal} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BurgerMenuService {
  private _isBurgerMenuOpened = signal(false);
  isBurgerMenuOpened = this._isBurgerMenuOpened.asReadonly();

  private _categoryMenuActiveId = signal<number | null>(null);
  categoryMenuActiveId = this._categoryMenuActiveId.asReadonly();


  setBurgerMenuStatus(status: boolean) {
    this._isBurgerMenuOpened.set(status);
  }

  toggleBurgerMenuStatus() {
    this._isBurgerMenuOpened.update(prev => !prev);
  }

  setCategoryMenuActiveId(categoryMenuActiveId: number | null) {
    this._categoryMenuActiveId.set(categoryMenuActiveId);
  }
}
