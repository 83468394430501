import {ChangeDetectionStrategy, Component, computed, inject, input, signal} from '@angular/core';
import {MenuItem} from '@shared/models/entities/Menu.type';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {NgClass, NgStyle} from '@angular/common';
import {
  BurgerMenuService
} from '@shared/components/layouts/main-layout/components/burger-menu/services/burger-menu.service';
import {MenuUrlPipe} from '@shared/pipes/menu-url.pipe';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';
import {BreakpointsConstants} from '@core/constants/breakpoints.constants';

@Component({
  selector: 'app-menu-group',
  imports: [
    IconComponent,
    NgClass,
    MenuUrlPipe,
    NgStyle
  ],
  templateUrl: './menu-group.component.html',
  styleUrl: './menu-group.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openClose', [
      state('open', style({
        height: '*',
        opacity: '1',
      })),
      state('closed', style({
        height: '0px',
        opacity: '0',
      })),
      transition('closed => open', [
        animate('0.15s ease-in-out')
      ]),
      transition('open => closed', [
        animate('0.15s ease-in-out')
      ])
    ]),
  ],
})
export class MenuGroupComponent {
  private burgerMenuService = inject(BurgerMenuService);
  private pd = inject(PlatformDetectorService);
  protected readonly IconSize = IconSize;
  appearance = input<MenuGroupAppearance>(MenuGroupAppearance.PRIMARY);
  item = input.required<MenuItem>();
  openedOnDesktop = computed(() => {
    const size = this.pd.currentScreenSize();
    if (size) {
      return size >= BreakpointsConstants.lg;
    } else {
      return false;
    }
  });

  isBurgerMenuOpened = this.burgerMenuService.isBurgerMenuOpened;
  showChildren = signal(false);
  isOpen = computed(() => (this.isBurgerMenuOpened() && this.showChildren() || this.openedOnDesktop()));


  toggleShow() {
    this.showChildren.update(prev => !prev);
  }
}

export enum MenuGroupAppearance {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
}
