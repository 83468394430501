import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'inactive',
  standalone: true
})
export class InactivePipe implements PipeTransform {

  transform(menuItemId: number, activeItemId: number | null): boolean {
    return activeItemId !== null && activeItemId !== menuItemId;
  }
}
