@let widePhoto = banner().menuImages[0];
@let normalPhoto = banner().menuImages[1];

<article class="item">
  @if (widePhoto && normalPhoto) {
    @let image = appearance === CollectionAppearance.WIDE ? widePhoto : normalPhoto;
    <app-image class="image" [src]="image.src | imageResizer : {w: image.width}" [alt]="image.altText"></app-image>
  }
  <h4 class="title">
    <a class="full-block-link" [href]="banner().buttonUrl">{{banner().name}}</a>
  </h4>
</article>
