import {ChangeDetectionStrategy, Component, input} from '@angular/core';
import {
  CollectionAppearance,
  CollectionComponent
} from '@shared/components/layouts/main-layout/components/burger-menu/collections/collection/collection.component';
import {NgClass} from '@angular/common';
import {MainPageBanner} from '@shared/models/entities/MainPageBanner.type';

@Component({
    selector: 'app-collections',
    imports: [
        CollectionComponent,
        NgClass
    ],
    templateUrl: './collections.component.html',
    styleUrl: './collections.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CollectionsComponent {
  collections = input.required<MainPageBanner[]>();
  protected readonly CollectionAppearance = CollectionAppearance;
}
