import {AfterContentInit, ChangeDetectionStrategy, Component, computed, inject, signal} from '@angular/core';
import {IconButtonComponent} from '@shared/components/ui/buttons/icon-button/icon-button.component';
import {IconComponent, IconSize} from '@shared/components/ui/icons/icon/icon.component';
import {HeaderMessageService} from '@shared/services/api/header-message.service';
import {AsyncPipe, NgClass} from '@angular/common';
import {SessionStorageService} from '@shared/services/storage/session-storage.service';
import {PlatformDetectorService} from '@shared/services/platform-detector.service';
import {toSignal} from '@angular/core/rxjs-interop';
import {shareReplay} from 'rxjs';

@Component({
  selector: 'app-header-message',
  imports: [
    IconButtonComponent,
    IconComponent,
    AsyncPipe,
    NgClass
  ],
  templateUrl: './header-message.component.html',
  styleUrl: './header-message.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMessageComponent implements AfterContentInit {
  private HEADER_MESSAGE_CLOSED_KEY = 'header-message-closed';
  private headerMessageService = inject(HeaderMessageService);
  private sessionStorageService = inject(SessionStorageService);
  private platformDetectorService = inject(PlatformDetectorService);

  protected readonly IconSize = IconSize;

  message$ = this.headerMessageService.getHeaderMessage().pipe(shareReplay());
  message = toSignal(this.message$);

  showMessage = computed(() => !this.closed() && this.isBrowser() && !!this.message());

  closed = signal(false);
  isBrowser = this.platformDetectorService.isBrowser;

  ngAfterContentInit() {
    this.closed.set(this.getSessionStorageBooleanValue());
  }

  close() {
    this.sessionStorageService.saveData(this.HEADER_MESSAGE_CLOSED_KEY, 'true');
    this.closed.set(this.getSessionStorageBooleanValue());
  }

  private getSessionStorageBooleanValue(): boolean {
    const value = this.sessionStorageService.getData(this.HEADER_MESSAGE_CLOSED_KEY);
    return !!(value && value === 'true');
  }
}
