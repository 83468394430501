@let message = message$ | async;

<aside class="container" [ngClass]="{'opened': showMessage()}">
  @if (message && showMessage()) {
    <span>{{ message.message }}</span>
    <app-icon-button class="close" [ariaLabel]="'close'" (click)="close()">
      <app-icon [src]="'/images/icons/x-close.svg'" [size]="{size: IconSize['w-18']}"></app-icon>
    </app-icon-button>
  }
</aside>



