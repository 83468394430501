import {Directive, ElementRef, HostListener, inject} from '@angular/core';
import {NavigateService} from '@shared/services/navigate.service';
import {LoginTooltipComponent} from '@pages/profile/my-profile/login-tooltip/login-tooltip.component';
import {AuthService} from '@shared/services/auth/auth.service';
import {RoutesConstants} from '@core/constants/routes.constants';
import {ModalService, ModalType} from '@shared/components/modal/modal.service';

@Directive({
  selector: '[appGoToFavorites]'
})
export class GoToFavoritesDirective {
  private navigateService = inject(NavigateService);
  private authService = inject(AuthService);
  private modalService = inject(ModalService);
  private elementRef = inject(ElementRef);

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    this.goToFavorite();
  }

  goToFavorite() {
    if (this.authService.isUserLogged()) {
      this.navigateService.navigate([RoutesConstants.PROFILE.path, RoutesConstants.PROFILE.children.FAVORITES.path])
    } else {
      this.modalService.show({
        component: LoginTooltipComponent,
        inputs: {}
      }, ModalType.TOOLTIP, this.elementRef?.nativeElement)
    }
  }


}
